import { environment } from '../environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UsersService {
    constructor(http) {
        this.http = http;
    }
    errorHandler(err) {
        if (err.error && err.error.message) {
            alert(err.error.message);
        }
        else {
            alert('Feil på url: ' + err.url + '\n Status code: ' + err.status + '\n' + err.message);
        }
        return throwError(err);
    }
    getUsers() {
        return this.http.get(environment.BACKEND_SERVER_URL + '/systemuser', { withCredentials: true });
    }
    saveUserData(userId, userData) {
        return this.http.post(environment.BACKEND_SERVER_URL + '/systemuser/' + userId, userData, { withCredentials: true });
    }
    createNewUser(userData) {
        return this.http.post(environment.BACKEND_SERVER_URL + '/systemuser', userData, { withCredentials: true })
            .pipe(catchError(this.errorHandler));
    }
    deleteUser(userId) {
        return this.http.delete(environment.BACKEND_SERVER_URL + '/systemuser/' + userId, { withCredentials: true });
    }
}
UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.HttpClient)); }, token: UsersService, providedIn: "root" });
