import { environment } from '../environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./content.service";
import * as i2 from "@angular/common/http";
export class CategoriesService {
    constructor(contentService, http) {
        this.contentService = contentService;
        this.http = http;
        this.categories = [];
    }
    ngOnInit() {
        this.contentService.fetchContent('/categories').subscribe(categories => {
            this.categories = categories;
        });
    }
    errorHandler(err) {
        if (err.error && err.error.message) {
            alert(err.error.message);
        }
        else if (err.error && err.error._issues) {
            alert(Object.entries(err.error._issues).map(entry => {
                return entry[0] + ' : ' + entry[1];
            }).join('\n'));
        }
        else {
            alert('Feil på url: ' + err.url + '\n Status code: ' + err.status + '\n' + err.message);
        }
        return throwError(err);
    }
    getCategories() {
        //return of(this.categories);
        return this.contentService.fetchContent('/categories');
    }
    deleteCategory(category) {
        return this.http.delete(environment.BACKEND_SERVER_URL + '/category/' + category.id, { withCredentials: true })
            .pipe(catchError(this.errorHandler));
    }
    addCategory(category) {
        if (!category.id) {
            delete category.id;
        }
        return this.http.post(environment.BACKEND_SERVER_URL + '/category', category, { withCredentials: true })
            .pipe(catchError(this.errorHandler));
    }
    updateCategory(category) {
        return this.http.post(environment.BACKEND_SERVER_URL + '/category/' + category.id, category, { withCredentials: true })
            .pipe(catchError(this.errorHandler));
    }
}
CategoriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoriesService_Factory() { return new CategoriesService(i0.ɵɵinject(i1.ContentService), i0.ɵɵinject(i2.HttpClient)); }, token: CategoriesService, providedIn: "root" });
