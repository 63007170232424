import { environment } from '../environments/environment';
import { throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./content.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/material/snack-bar";
export class AuthService {
    constructor(http, contentService, router, _snackBar) {
        this.http = http;
        this.contentService = contentService;
        this.router = router;
        this._snackBar = _snackBar;
        this.sessionInfo$ = new BehaviorSubject(null);
    }
    intercept(req, next) {
        return next.handle(req)
            .pipe(catchError(err => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    // this.sessionInfo = null;
                    this.sessionInfo$.next(null);
                    this.router.navigate(['']);
                    this._snackBar.open('Bruker er ikke innlogget med nødvendige rettigheter', 'lukk');
                }
            }
            return throwError(err);
        }));
    }
    errorHandler(err) {
        if (err.error && err.error.message) {
            alert(err.error.message);
        }
        else if (err.error && err.error._issues) {
            alert(Object.entries(err.error._issues).map(entry => {
                return entry[0] + ' : ' + entry[1];
            }).join('\n'));
        }
        else {
            alert('Feil på url: ' + err.url + '\n Status code: ' + err.status + '\n' + err.message);
        }
        return throwError(err);
    }
    fetchSessionInfoOnInit() {
        return this.http.get(environment.BACKEND_SERVER_URL + '/sessioninfo', { withCredentials: true })
            .pipe(catchError(this.errorHandler))
            .subscribe((sessionInfo) => {
            this.sessionInfo$.next(sessionInfo);
        });
    }
    login(userInfo) {
        const content = {
            username: userInfo.username,
            password: userInfo.password
        };
        const httpOptions = {
            withCredentials: true
        };
        return this.http.post(environment.BACKEND_SERVER_URL + '/login', content, httpOptions)
            .pipe(tap((sessionInfo) => {
            this.sessionInfo$.next(sessionInfo);
        }));
    }
    resetPassword(username) {
        const content = {
            username: username
        };
        const httpOptions = {
            withCredentials: true
        };
        return this.http.post(environment.BACKEND_SERVER_URL + '/iforgot', content, httpOptions)
            .pipe(catchError(this.errorHandler));
    }
    setPassword(token, password) {
        const content = {
            token: token,
            password: password,
        };
        const httpOptions = {
            withCredentials: true
        };
        return this.http.post(environment.BACKEND_SERVER_URL + '/resetpassword', content, httpOptions)
            .pipe(catchError(this.errorHandler));
    }
    logout() {
        return this.http.get(environment.BACKEND_SERVER_URL + '/logout', { withCredentials: true })
            .pipe(catchError(this.errorHandler))
            .subscribe(res => {
            this.sessionInfo$.next(null);
            this.router.navigate(['/']);
        }, err => {
            console.error('Could not logout', err);
        });
    }
    authenticate(uname, pwd) {
        return this.login({ username: uname, password: pwd });
    }
    getUserInfo() {
        return this.sessionInfo$
            .pipe(map((sessionInfo) => {
            return sessionInfo ? sessionInfo.user : null;
        }));
    }
    isAuthenticated() {
        return this.sessionInfo$
            .pipe(map((sessionInfo) => {
            return sessionInfo && sessionInfo.logged_in;
        }));
    }
    isAdminUser() {
        return this.sessionInfo$
            .pipe(map((sessionInfo) => {
            if (sessionInfo && sessionInfo.user && sessionInfo.user.adminuser) {
                return true;
            }
            else {
                return false;
            }
        }));
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ContentService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.MatSnackBar)); }, token: AuthService, providedIn: "root" });
