import * as i0 from "@angular/core";
import * as i1 from "./content.service";
export class ImageListService {
    constructor(contentService) {
        this.contentService = contentService;
    }
    getImagesByCategory(categoryId, searchParams) {
        const queryUrl = '/foto/category/' + categoryId;
        return this.contentService.fetchContentWithQueryParams(queryUrl, searchParams);
    }
    getAllPhotoes(searchParams) {
        const queryUrl = '/foto';
        return this.contentService.fetchContentWithQueryParams(queryUrl, searchParams);
    }
    getImagesBySearchParams(searchParams) {
        const queryUrl = '/foto/search';
        return this.contentService.fetchContentWithQueryParams(queryUrl, searchParams);
    }
}
ImageListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImageListService_Factory() { return new ImageListService(i0.ɵɵinject(i1.ContentService)); }, token: ImageListService, providedIn: "root" });
